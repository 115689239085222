import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from 'gatsby'

// Styles
import styles from "../styles/pages/success.module.scss"

const SuccessPage = () => (
  <>
    <Header />
    <SEO title="Success" />
    <div className={styles.main}>
      <div className={styles.content}>
        <h1>Thank You For Getting In Touch</h1>
        <p>We will get back to you shortly</p>
        <Link to="/">
          <button className={styles.button}>Go Back</button>
        </Link>
      </div>
    </div>
    <Footer />
  </>
)

export default SuccessPage
